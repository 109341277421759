export const MODAL_TYPES = {
  WITHDRAW_BONUS: 'WITHDRAW_BONUS',
  COMPLAIN: 'COMPLAIN',
  VIEW_PDF: 'VIEW_PDF',
  WITHDRAW_SALARY: 'WITHDRAW_SALARY'
}

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
}

export const ROLES = {
  SALES: 'sales',
  RET: 'ret',
  LEADER: 'leader',
  APPROVER: 'approver',
  ADMIN: 'administrator',
  AUTHOR: 'author'
}

export const STATUS_REGISTER = [
  { name: 'Đang xử lí', value: 'progress' },
  { name: 'Đã xử lí', value: 'done' }
]

export const TYPE_REGISTER = [
  { name: 'Đăng kí khoá học', value: 'register' },
  { name: 'Theo dõi diễn giả', value: 'follow' }
]

export const STATUS_SALARY_BONUS_DEFAULT = [1, 2, 3, 4, 5, 6]

export const CHART_OPTIONS = {
  BONUS: 'bonus',
  FTD: 'ftd',
  CUSTOMER: 'customer'
}

export const ROLES_SIGNUP = [
  {
    id: 1,
    name: 'Sales'
  },
  {
    id: 7,
    name: 'Ret'
  }
]

export const EDITER = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const REGEX = {
  PHONE: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
  USER_NAME: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
  FULL_NAME_CAPITALIZE:
    /^[A-Z\u00C0-\u024F\u1E00-\u1EFF-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF][a-z,.'-\u00C0-\u024F\u1E00-\u1EFF-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+(?: [A-Z\u00C0-\u024F\u1E00-\u1EFF-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF][a-z,.'\u00C0-\u024F\u1E00-\u1EFF-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+)*$/g,
  ONLY_NUMBER: /^\d+$/,
  SPACE: /^\S*$/
}

export const BONUS = 'bonus'
export const SALARY = 'salary'
export const ACCEPT = 'accept'
export const REJECT = 'reject'
export const TYPE_APPROVE = {
  ACCEPT: 'accept',
  REJECT: 'reject'
}

export const TYPE_COMPLETED = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const WIDTH = {
  TEXT_FIELD_SEARCH: 515,
  FORM_SELECT: 250
}

export const AMOUNT_OPTION = [
  {
    id: 0,
    name: 'pages.approverBonusRequest.all',
    gte: 1,
    lte: null
  },

  {
    id: 1,
    name: 'pages.approverBonusRequest.below',
    gte: 1,
    lte: 1000000
  },
  {
    id: 2,
    name: 'pages.approverBonusRequest.to',
    gte: 1000000,
    lte: 5000000
  },
  {
    id: 3,
    name: 'pages.approverBonusRequest.to1',
    gte: 5000000,
    lte: 10000000
  },
  {
    id: 4,
    name: 'pages.approverBonusRequest.above',
    gte: 10000000,
    lte: null
  }
]

export const NUM_OF_EMPLOYEES_OPTIONS = [
  {
    id: 0,
    name: 'pages.manageOffice.all',
    min: 0,
    max: 99000
  },
  {
    id: 1,
    name: 'pages.manageOffice.below',
    min: 0,
    max: 499
  },
  {
    id: 2,
    name: 'pages.manageOffice.to',
    min: 500,
    max: 1000
  },
  {
    id: 3,
    name: 'pages.manageOffice.above',
    min: 1001,
    max: 99000
  }
]

export const NUM_OF_EMPLOYEES_TEAM_OPTIONS = [
  {
    id: 0,
    name: 'pages.manageTeams.all',
    min: 0,
    max: 100
  },
  {
    id: 1,
    name: 'pages.manageTeams.below',
    min: 0,
    max: 4
  },
  {
    id: 2,
    name: 'pages.manageTeams.to',
    min: 5,
    max: 10
  },
  {
    id: 3,
    name: 'pages.manageTeams.above',
    min: 11,
    max: 100
  }
]

export const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250
    }
  }
}

export const OPTIONS_BANK = [
  {
    value: 'Ngân hàng TMCP Á Châu(ACB)'
  },
  {
    value: 'Ngân hàng TMCP Tiên Phong(TPBank)'
  },
  {
    value: 'Ngân hàng TMCP Đông Á(Đông Á Bank, DAB)'
  },
  {
    value: 'Ngân Hàng TMCP Đông Nam Á(SeABank)'
  },
  {
    value: 'Ngân hàng TMCP An Bình(ABBANK)'
  },
  {
    value: 'Ngân hàng TMCP Bắc Á(BacABank)'
  },
  {
    value: 'Ngân hàng TMCP Bản Việt(VietCapitalBank)'
  },
  {
    value: 'Ngân hàng TMCP Hàng hải Việt Nam(MSB)'
  },
  {
    value: 'Ngân hàng TMCP Kỹ Thương Việt Nam(Techcombank, TCB)'
  },
  {
    value: 'Ngân hàng TMCP Kiên Long(KienLongBank)'
  },
  {
    value: 'Ngân hàng TMCP Nam Á(Nam A Bank	)'
  },
  {
    value: 'Ngân hàng TMCP Quốc Dân(National Citizen Bank, NCB)'
  },
  {
    value: 'Ngân hàng TMCP Việt Nam Thịnh Vượng(VPBank)'
  },
  {
    value: 'Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh(HDBank)'
  },
  {
    value: 'Ngân hàng TMCP Phương Đông(Orient Commercial Bank, OCB)'
  },
  {
    value: 'Ngân hàng TMCP Quân đội(Military Bank, MB)'
  },
  {
    value: 'Ngân hàng TMCP Đại chúng(PVcombank)'
  },
  {
    value: 'Ngân hàng TMCP Quốc tế Việt Nam(VIBBank, VIB)'
  },
  {
    value: 'Ngân hàng TMCP Sài Gòn(Saigonbank, SGB)'
  },
  {
    value: 'Ngân hàng TMCP Sài Gòn – Hà Nội(SHBank, SHB)'
  },
  {
    value: 'Ngân hàng TMCP Sài Gòn Thương Tín(Sacombank, STB)'
  },
  {
    value: 'Ngân hàng TMCP Việt Á(VietABank, VAB)'
  },
  {
    value: 'Ngân hàng TMCP Bảo Việt(BaoVietBank, BVB)'
  },
  {
    value: 'Ngân hàng TMCP Việt Nam Thương Tín(VietBank)'
  },
  {
    value: 'Ngân Hàng TMCP Xăng Dầu Petrolimex(Petrolimex Group Bank, PG Bank)'
  },
  {
    value: 'Ngân Hàng TMCP Xuất Nhập khẩu Việt Nam(Eximbank, EIB)'
  },
  {
    value: 'Ngân Hàng TMCP Bưu điện Liên Việt(LienVietPostBank, LPB)'
  },
  {
    value: 'Ngân Hàng TMCP Ngoại thương Việt Nam(Vietcombank, VCB)'
  },
  {
    value: 'Ngân Hàng TMCP Công Thương Việt Nam(VietinBank, CTG)'
  },
  {
    value: 'Ngân Hàng TMCP Đầu tư và Phát triển Việt Nam'
  },
  {
    value: 'Ngân hàng Chính sách xã hội(NHCSXH/VBSP)'
  },
  {
    value: 'Ngân hàng Phát triển Việt Nam(VDB)'
  },
  {
    value: 'Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam(CB)'
  },
  {
    value: 'Ngân hàng Thương mại TNHH MTV Đại Dương(Oceanbank)'
  },
  {
    value: 'Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu(GPBank)'
  },
  {
    value: 'Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam(Agribank)'
  }
]

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15]

export const ROLE_MAP = {
  administrator: 'Quản trị viên',
  author: 'Diễn giả',
  user: 'Người dùng'
}

export const ROLE_OPTIONS = [
  { label: 'Tất cả', value: null },
  {
    label: ROLE_MAP.administrator,
    value: 'administrator'
  },
  {
    label: ROLE_MAP.author,
    value: 'author'
  },
  {
    label: ROLE_MAP.user,
    value: 'user'
  }
]
