import { addSeconds, compareAsc, format } from 'date-fns'

interface DateComparisonInput {
  lastedDate: string | Date
  publishAtDate: string | Date
}

const filterRoutesByRole = (role, routes: any) =>
  routes
    .filter((route) => (route.roles ? route.roles.includes(role) : true))
    .map((route) => ({
      ...route,
      children: route.children && filterRoutesByRole(role, route.children)
    }))

export const formatDuration = (seconds: number) => {
  const helperDate = addSeconds(new Date(0), seconds)
  return format(helperDate, 'mm:ss')
}

export const validateValidImageURL = (imgURL: string) => {
  let image = new Image()

  image.onload = function onload() {
    // @ts-ignore
    if (this.width > 0) {
      return true
    }
  }
  image.onerror = function onerror() {
    return false
  }
  image.src = imgURL

  return false
}

export const trackChanges = (originalArray, updatedArray) => {
  const originalIds = originalArray.map((item) => item.id)
  const updatedIds = updatedArray.map((item) => item.id)
  const addedItems = updatedArray.filter((item) => !originalIds.includes(item.id))
  const removedItems = originalArray.filter((item) => !updatedIds.includes(item.id))
  return { addedItems, removedItems }
}

export const shortenString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }
  return `${str.slice(0, maxLength)}...`
}

/**
 * Compare lastedDate and publishAtDate.
 * @param dates - Object contain lastedDate and publishAtDate.
 * @returns {boolean} return True if lastedDate more than publishAtDate and contrary return False.
 */
export function compareDates({ lastedDate, publishAtDate }: DateComparisonInput): boolean {
  const result = compareAsc(new Date(lastedDate), new Date(publishAtDate))

  return result <= 0
}

export const isValidUrl = (urlString: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(urlString)
    return true
  } catch (e) {
    return false
  }
}

export { filterRoutesByRole }
